import React, { useState } from 'react';
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'
import NewMain from '../headerone/newMain'
import { useParams } from 'react-router-dom';
import { Lightbox } from "react-modal-image";
import ModalImage from "react-modal-image";

function GalleryDetail() { 
    const { screenname } = useParams();
    const [myArray,   ] = useState([]);
    let newarry;
    let newarry_text;
    const gallery_iamges =[
        `${process.env.PUBLIC_URL}/assets/img/new_images/gallery1/gallery01.jpg`,
        `${process.env.PUBLIC_URL}/assets/img/new_images/gallery1/gallery02.jpg`,
        `${process.env.PUBLIC_URL}/assets/img/new_images/gallery1/gallery03.jpg`,
        `${process.env.PUBLIC_URL}/assets/img/new_images/gallery1/gallery04.jpg`,
        `${process.env.PUBLIC_URL}/assets/img/new_images/gallery1/gallery05.jpg`,
        `${process.env.PUBLIC_URL}/assets/img/new_images/gallery1/gallery06.jpg`,
        `${process.env.PUBLIC_URL}/assets/img/new_images/gallery1/gallery10.jpg`,
        `${process.env.PUBLIC_URL}/assets/img/new_images/gallery1/gallery11.jpg`,
        `${process.env.PUBLIC_URL}/assets/img/new_images/gallery1/gallery12.jpg`,
        `${process.env.PUBLIC_URL}/assets/img/new_images/gallery1/gallery13.jpg`,
        `${process.env.PUBLIC_URL}/assets/img/new_images/gallery1/gallery14.jpg`,
        `${process.env.PUBLIC_URL}/assets/img/new_images/gallery1/gallery15.jpg`,
        `${process.env.PUBLIC_URL}/assets/img/new_images/gallery1/gallery16.jpg`
         
    ]


    const convocation8th_ceremony =[
      `${process.env.PUBLIC_URL}/assets/img/new_images/8th-convocation-ceremony-photo-on-16-march-2024/_C9A0847.JPG`,
      `${process.env.PUBLIC_URL}/assets/img/new_images/8th-convocation-ceremony-photo-on-16-march-2024/_C9A0872.JPG`,
      `${process.env.PUBLIC_URL}/assets/img/new_images/8th-convocation-ceremony-photo-on-16-march-2024/_C9A0888.JPG`,
      `${process.env.PUBLIC_URL}/assets/img/new_images/8th-convocation-ceremony-photo-on-16-march-2024/_C9A0911.JPG`,
      `${process.env.PUBLIC_URL}/assets/img/new_images/8th-convocation-ceremony-photo-on-16-march-2024/_C9A1029.JPG`,
      `${process.env.PUBLIC_URL}/assets/img/new_images/8th-convocation-ceremony-photo-on-16-march-2024/_C9A1038.JPG`,
      `${process.env.PUBLIC_URL}/assets/img/new_images/8th-convocation-ceremony-photo-on-16-march-2024/_C9A1054.JPG`,
      `${process.env.PUBLIC_URL}/assets/img/new_images/8th-convocation-ceremony-photo-on-16-march-2024/_C9A1056.JPG`,
      `${process.env.PUBLIC_URL}/assets/img/new_images/8th-convocation-ceremony-photo-on-16-march-2024/_C9A1057.JPG`,
      `${process.env.PUBLIC_URL}/assets/img/new_images/8th-convocation-ceremony-photo-on-16-march-2024/_C9A1058.JPG`,
      `${process.env.PUBLIC_URL}/assets/img/new_images/8th-convocation-ceremony-photo-on-16-march-2024/_C9A1085.JPG`,
      `${process.env.PUBLIC_URL}/assets/img/new_images/8th-convocation-ceremony-photo-on-16-march-2024/_C9A1089.JPG`,
      `${process.env.PUBLIC_URL}/assets/img/new_images/8th-convocation-ceremony-photo-on-16-march-2024/_C9A1098.JPG`,
      `${process.env.PUBLIC_URL}/assets/img/new_images/8th-convocation-ceremony-photo-on-16-march-2024/_C9A1107.JPG`,
      `${process.env.PUBLIC_URL}/assets/img/new_images/8th-convocation-ceremony-photo-on-16-march-2024/_C9A1203.JPG`,
      `${process.env.PUBLIC_URL}/assets/img/new_images/8th-convocation-ceremony-photo-on-16-march-2024/_C9A1740.JPG`,
      `${process.env.PUBLIC_URL}/assets/img/new_images/8th-convocation-ceremony-photo-on-16-march-2024/_C9A1790.JPG`,
      `${process.env.PUBLIC_URL}/assets/img/new_images/8th-convocation-ceremony-photo-on-16-march-2024/_C9A1797.JPG`,
      `${process.env.PUBLIC_URL}/assets/img/new_images/8th-convocation-ceremony-photo-on-16-march-2024/_C9A1819.JPG`,
      `${process.env.PUBLIC_URL}/assets/img/new_images/8th-convocation-ceremony-photo-on-16-march-2024/_C9A1828.JPG`
       
  ]


  const drrajeev_april_2024 =[
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3714.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3715.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3716.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3718.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3719.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3720.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3722.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3723.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3725.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3727.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3728.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3729.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3730.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3731.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3733.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3734.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3735.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3736.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3737.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3738.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3739.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3741.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3743.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3744.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3745.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3747.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3748.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3749.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3750.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3753.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3754.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3755.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3756.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3757.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3758.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3759.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3760.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3761.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3764.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3765.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3766.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3767.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3768.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3769.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3770.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3771.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3773.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3774.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3775.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3776.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3777.JPG`,
    `${process.env.PUBLIC_URL}/assets/img/new_images/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024/IMG_3779.JPG`, 
   
     
]



const envisage_2k24_photo =[
  `${process.env.PUBLIC_URL}/assets/img/new_images/envisage-2k24-photo-2223-feb-2024/087A7998.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/envisage-2k24-photo-2223-feb-2024/087A8054.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/envisage-2k24-photo-2223-feb-2024/087A8066.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/envisage-2k24-photo-2223-feb-2024/087A8273.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/envisage-2k24-photo-2223-feb-2024/087A8402.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/envisage-2k24-photo-2223-feb-2024/087A8459.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/envisage-2k24-photo-2223-feb-2024/087A8498.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/envisage-2k24-photo-2223-feb-2024/087A8524.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/envisage-2k24-photo-2223-feb-2024/DSC_9578.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/envisage-2k24-photo-2223-feb-2024/DSC_9692.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/envisage-2k24-photo-2223-feb-2024/DSC_9710.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/envisage-2k24-photo-2223-feb-2024/DSC_9711.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/envisage-2k24-photo-2223-feb-2024/DSC00591.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/envisage-2k24-photo-2223-feb-2024/DSC00676.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/envisage-2k24-photo-2223-feb-2024/IMG_2430.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/envisage-2k24-photo-2223-feb-2024/IMG_2485.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/envisage-2k24-photo-2223-feb-2024/IMG_2499.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/envisage-2k24-photo-2223-feb-2024/IMG_2839.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/envisage-2k24-photo-2223-feb-2024/IMG_2886.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/envisage-2k24-photo-2223-feb-2024/IMG_3138.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/envisage-2k24-photo-2223-feb-2024/IMG_3154.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/envisage-2k24-photo-2223-feb-2024/IMG_3298.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/envisage-2k24-photo-2223-feb-2024/IMG_3347.JPG`,

   
   
]



const farewell_party_celebration =[
  `${process.env.PUBLIC_URL}/assets/img/new_images/farewell-party-celebration-pharmacy-dept-on-31-may-2024/IMG_4251.JPG`, 
  `${process.env.PUBLIC_URL}/assets/img/new_images/farewell-party-celebration-pharmacy-dept-on-31-may-2024/IMG_4252.JPG`, 
  `${process.env.PUBLIC_URL}/assets/img/new_images/farewell-party-celebration-pharmacy-dept-on-31-may-2024/IMG_4257.JPG`, 
  `${process.env.PUBLIC_URL}/assets/img/new_images/farewell-party-celebration-pharmacy-dept-on-31-may-2024/IMG_4267.JPG`, 
  `${process.env.PUBLIC_URL}/assets/img/new_images/farewell-party-celebration-pharmacy-dept-on-31-may-2024/IMG_4317.JPG`, 
  `${process.env.PUBLIC_URL}/assets/img/new_images/farewell-party-celebration-pharmacy-dept-on-31-may-2024/IMG_4433.JPG`, 
  `${process.env.PUBLIC_URL}/assets/img/new_images/farewell-party-celebration-pharmacy-dept-on-31-may-2024/IMG_4438.JPG`, 
  `${process.env.PUBLIC_URL}/assets/img/new_images/farewell-party-celebration-pharmacy-dept-on-31-may-2024/IMG_4478.JPG`, 
  `${process.env.PUBLIC_URL}/assets/img/new_images/farewell-party-celebration-pharmacy-dept-on-31-may-2024/IMG_4481.JPG`, 
  `${process.env.PUBLIC_URL}/assets/img/new_images/farewell-party-celebration-pharmacy-dept-on-31-may-2024/IMG_4485.JPG`, 

   
   
]




const international_yoga_day =[
  `${process.env.PUBLIC_URL}/assets/img/new_images/international-yoga-day-celebration-photo-on-10-june-2024/IMG_4780.JPG`,  
  `${process.env.PUBLIC_URL}/assets/img/new_images/international-yoga-day-celebration-photo-on-10-june-2024/IMG_4813.JPG`, 
  `${process.env.PUBLIC_URL}/assets/img/new_images/international-yoga-day-celebration-photo-on-10-june-2024/IMG_4815.JPG`, 
  `${process.env.PUBLIC_URL}/assets/img/new_images/international-yoga-day-celebration-photo-on-10-june-2024/IMG_4826.JPG`, 
  `${process.env.PUBLIC_URL}/assets/img/new_images/international-yoga-day-celebration-photo-on-10-june-2024/IMG_4827.JPG`, 
  `${process.env.PUBLIC_URL}/assets/img/new_images/international-yoga-day-celebration-photo-on-10-june-2024/IMG_4830.JPG`, 
  `${process.env.PUBLIC_URL}/assets/img/new_images/international-yoga-day-celebration-photo-on-10-june-2024/IMG_4839.JPG`, 
  `${process.env.PUBLIC_URL}/assets/img/new_images/international-yoga-day-celebration-photo-on-10-june-2024/IMG_4843.JPG`, 
  `${process.env.PUBLIC_URL}/assets/img/new_images/international-yoga-day-celebration-photo-on-10-june-2024/IMG_4856.JPG`, 
  `${process.env.PUBLIC_URL}/assets/img/new_images/international-yoga-day-celebration-photo-on-10-june-2024/IMG_4862.JPG` 

   
   
]

const mega_job_fair =[
  `${process.env.PUBLIC_URL}/assets/img/new_images/mega-job-fair-cum-hr-summit-on-15-march-2024/_C9A0372.JPG`,  
  `${process.env.PUBLIC_URL}/assets/img/new_images/mega-job-fair-cum-hr-summit-on-15-march-2024/_C9A0403.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/mega-job-fair-cum-hr-summit-on-15-march-2024/_C9A0487.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/mega-job-fair-cum-hr-summit-on-15-march-2024/_C9A0490.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/mega-job-fair-cum-hr-summit-on-15-march-2024/_C9A0492.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/mega-job-fair-cum-hr-summit-on-15-march-2024/_C9A0496.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/mega-job-fair-cum-hr-summit-on-15-march-2024/_C9A0603.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/mega-job-fair-cum-hr-summit-on-15-march-2024/_C9A0613.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/mega-job-fair-cum-hr-summit-on-15-march-2024/_C9A0722.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/mega-job-fair-cum-hr-summit-on-15-march-2024/_C9A0736.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/mega-job-fair-cum-hr-summit-on-15-march-2024/_C9A0743.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/mega-job-fair-cum-hr-summit-on-15-march-2024/_C9A0757.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/mega-job-fair-cum-hr-summit-on-15-march-2024/_C9A0762.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/mega-job-fair-cum-hr-summit-on-15-march-2024/_C9A0764.JPG`, 

   
   
]



const news_reporters_visit  =[
  `${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2775.JPG`,   
  `${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2777.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2779.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2780.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2781.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2783.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2786.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2790.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2791.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2792.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2794.JPG`,
`${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2796.JPG`,
`${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2797.JPG`,
`${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2798.JPG`,
`${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2799.JPG`,
`${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2800.JPG`,
`${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2801.JPG`,
`${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2802.JPG`,
`${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2804.JPG`,
`${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2805.JPG`,
`${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2806.JPG`,
`${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2807.JPG`,
`${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2809.JPG`,
`${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2810.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2811.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2812.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2813.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2814.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2815.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2816.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2817.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2818.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2819.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2820.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2820.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2821.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2822.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2823.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2824.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2825.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2826.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2827.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/news-reporters-visit-on-12-03-2024/IMG_2828.JPG`,

   
   
]



const sh_gopal_sharma_death_anniversary =[
  `${process.env.PUBLIC_URL}/assets/img/new_images/sh-gopal-sharma-death-anniversary-photo-on-18-dec-2023/IMG_0983.JPG`,  
  `${process.env.PUBLIC_URL}/assets/img/new_images/sh-gopal-sharma-death-anniversary-photo-on-18-dec-2023/IMG_1000.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/sh-gopal-sharma-death-anniversary-photo-on-18-dec-2023/IMG_1008.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/sh-gopal-sharma-death-anniversary-photo-on-18-dec-2023/IMG_1019.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/sh-gopal-sharma-death-anniversary-photo-on-18-dec-2023/IMG_1020.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/sh-gopal-sharma-death-anniversary-photo-on-18-dec-2023/IMG_1023.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/sh-gopal-sharma-death-anniversary-photo-on-18-dec-2023/IMG_1029.JPG` 

   
   
]


const tug_of_war_photo  =[
  `${process.env.PUBLIC_URL}/assets/img/new_images/tug-of-war-photo-12-feb-2024/IMG_2912.JPG`,   
  `${process.env.PUBLIC_URL}/assets/img/new_images/tug-of-war-photo-12-feb-2024/IMG_2915.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/tug-of-war-photo-12-feb-2024/IMG_2919.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/tug-of-war-photo-12-feb-2024/IMG_2931.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/tug-of-war-photo-12-feb-2024/IMG_2937.JPG`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/tug-of-war-photo-12-feb-2024/IMG_2938.JPG`, 

   
   
]


const urja_2k23_freshers_party =[
  `${process.env.PUBLIC_URL}/assets/img/new_images/urja-2k23-freshers-party-celebration-photo-on-24-nov-2023/DSC_8893.JPG`, 
  `${process.env.PUBLIC_URL}/assets/img/new_images/urja-2k23-freshers-party-celebration-photo-on-24-nov-2023/DSC_8899.JPG`, 
  `${process.env.PUBLIC_URL}/assets/img/new_images/urja-2k23-freshers-party-celebration-photo-on-24-nov-2023/DSC_8906.JPG`, 
  `${process.env.PUBLIC_URL}/assets/img/new_images/urja-2k23-freshers-party-celebration-photo-on-24-nov-2023/DSC_8907.JPG`, 
  `${process.env.PUBLIC_URL}/assets/img/new_images/urja-2k23-freshers-party-celebration-photo-on-24-nov-2023/DSC_8925.JPG`, 
  `${process.env.PUBLIC_URL}/assets/img/new_images/urja-2k23-freshers-party-celebration-photo-on-24-nov-2023/DSC_8941.JPG`, 
  `${process.env.PUBLIC_URL}/assets/img/new_images/urja-2k23-freshers-party-celebration-photo-on-24-nov-2023/DSC_9086.JPG`,  

   
   
]



const freshers_welcome_party =[
  `${process.env.PUBLIC_URL}/assets/img/new_images/freshers_welcome_party/IMG_42505.jpg`, 
  `${process.env.PUBLIC_URL}/assets/img/new_images/freshers_welcome_party/IMG_42506.jpg`, 
  `${process.env.PUBLIC_URL}/assets/img/new_images/freshers_welcome_party/IMG_42507.jpg`, 
  
   
]


const health_eye_checkup_camp_09_oct_2024 =[
  `${process.env.PUBLIC_URL}/assets/img/new_images/health_eye_checkup_camp_09_oct_2024/even01.jpg`, 
  `${process.env.PUBLIC_URL}/assets/img/new_images/health_eye_checkup_camp_09_oct_2024/even02.jpg`, 
  `${process.env.PUBLIC_URL}/assets/img/new_images/health_eye_checkup_camp_09_oct_2024/even03.jpg`, 
  `${process.env.PUBLIC_URL}/assets/img/new_images/health_eye_checkup_camp_09_oct_2024/even04.jpg`, 
  
   
]



const report_on_celebration_of_yoga_diwas =[
  `${process.env.PUBLIC_URL}/assets/img/new_images/report-on-celebration-of-yoga-diwas/agallery03.jpg`, 
  `${process.env.PUBLIC_URL}/assets/img/new_images/report-on-celebration-of-yoga-diwas/agallery04.jpg`, 
  `${process.env.PUBLIC_URL}/assets/img/new_images/report-on-celebration-of-yoga-diwas/agallery05.jpg`, 
  `${process.env.PUBLIC_URL}/assets/img/new_images/report-on-celebration-of-yoga-diwas/agallery06.jpg`, 
  `${process.env.PUBLIC_URL}/assets/img/new_images/report-on-celebration-of-yoga-diwas/agallery07.jpg`, 
  `${process.env.PUBLIC_URL}/assets/img/new_images/report-on-celebration-of-yoga-diwas/agallery08.jpg`, 
  
   
]


const report_on_celebration_of_yoga_diwas_test = "MVN University celebrates Yog Diwas under the aegis of Haryana Yog Aayog , Govt. of Haryana. Event initiated by Yog Guru Bijender Arya ,Yogesh Arya, VC Dr. Arun Garg, Pro VC Dr. N.P. Singh, Registrar Dr. Rajeev Ratan, Director Corporate Affair Sh. Narender Ahuja by lightning the lamp. Yog Guru Bijender Arya made and perform different type of asana to all people were present in the hall. Director Corporate Affair asks yoga is an integral part of all people. The programme was concluded with shanty path by the yoga guru.";





const report_on_international_conference_of_pharmacay =[
  `${process.env.PUBLIC_URL}/assets/img/new_images/report-on-international-conference-of-pharmacay/news01.jpg`,  
  `${process.env.PUBLIC_URL}/assets/img/new_images/report-on-international-conference-of-pharmacay/news02.jpg`, 
  `${process.env.PUBLIC_URL}/assets/img/new_images/report-on-international-conference-of-pharmacay/news03.jpg`, 
  
   
]


const report_on_international_conference_of_pharmacay_test = "MVN University organized International conference on 14th March. Approximatly 1500 students participated in this conference on this event Haryana Pharmacy Council Chairman Sh. Dhanesh Adlakha speaks about pharmacy which is directly connected to people’s health. Honorable  Dr. Arun Garg Vice Chancellor of MVN University informed in the press conference that apart from International conference, HR Summit, Job Fair there are several new courses going to start in campus with university focus & emphasis will also on placement.";



const report_on_educationists_lecture_in_iternational_conference =[
  `${process.env.PUBLIC_URL}/assets/img/new_images/report-on-educationists-lecture-in-iternational-conference/eves0502.jpg`,  
  `${process.env.PUBLIC_URL}/assets/img/new_images/report-on-educationists-lecture-in-iternational-conference/eves0503.jpg`,
  
   
]


const report_on_educationists_lecture_in_iternational_conference_text = "MVN University organized press conference to give information regarding three event that is to be held on 14-16 March, 2024 as International conference, HR Summit & Job Fair. Honorable Vice Chancellor of MVN University Dr. Arun Garg informed in the press conference that there are several new courses going to start in campus with university focus & emphasis also on placement. In international conference reputed educationists share their thought and experience with students about new opportunities new market trends, technologies etc.";







const report_on_annual_sports_meet =[
  `${process.env.PUBLIC_URL}/assets/img/new_images/report-on-annual-sports-meet/imes006.jpg`,  
  `${process.env.PUBLIC_URL}/assets/img/new_images/report-on-annual-sports-meet/imes007.jpg`,  
  `${process.env.PUBLIC_URL}/assets/img/new_images/report-on-annual-sports-meet/imes008.jpg`,  
  `${process.env.PUBLIC_URL}/assets/img/new_images/report-on-annual-sports-meet/imes009.jpg`,  
  
   
]


const report_on_annual_sports_meet_text = "Team of MVN University Palwal won the Tug of War competition and runner up in Volleyball in Annual sports meet held in Aravali Inter College. Honorable VC of MVN Dr. Arun Garg congratulates the team of MVN and says that sports play an important part for overall development of the students. Honorable Chairman, Registrar & DP Ram Kumar also congratulates to the students.";




const report_on_19th_aniversaryof_shri_gopal_sharma_ji_19_dec_2023 =[
  `${process.env.PUBLIC_URL}/assets/img/new_images/report-on-19th-aniversaryof-shri-gopal-sharma-ji-19-dec-2023/sime05aa06.jpg`,  
  `${process.env.PUBLIC_URL}/assets/img/new_images/report-on-19th-aniversaryof-shri-gopal-sharma-ji-19-dec-2023/sime05aa07.jpg`,  
  `${process.env.PUBLIC_URL}/assets/img/new_images/report-on-19th-aniversaryof-shri-gopal-sharma-ji-19-dec-2023/sime05aa08.jpg`,  
  `${process.env.PUBLIC_URL}/assets/img/new_images/report-on-19th-aniversaryof-shri-gopal-sharma-ji-19-dec-2023/sime05aa09.jpg`,  
  `${process.env.PUBLIC_URL}/assets/img/new_images/report-on-19th-aniversaryof-shri-gopal-sharma-ji-19-dec-2023/sime05aa09.jpg`,  
  `${process.env.PUBLIC_URL}/assets/img/new_images/report-on-19th-aniversaryof-shri-gopal-sharma-ji-19-dec-2023/sime05aa10.jpg`,  
  `${process.env.PUBLIC_URL}/assets/img/new_images/report-on-19th-aniversaryof-shri-gopal-sharma-ji-19-dec-2023/sime05aa11.jpg`,  
  
   
]


const report_on_19th_aniversaryof_shri_gopal_sharma_ji_19_dec_2023_text = "19th death anniversary of MVN Society founder Sh. Gopal Sharma was celebrated in MVN university campus. Honorable VC Dr. Arun Garg, Pro VC Dr. N.P. Singh, Registrar Rajeev Rattan and all staff member of MVN University pay tribute to MVN founder. On this occasion sports activities were conducted in campus under the guidance of DP Ram Kumar later a bhandara (feast) was also organized.";


const report_in_job_fair_cum_hr_summit =[
  `${process.env.PUBLIC_URL}/assets/img/new_images/report-in-job-fair-cum-hr-summit/news06.jpg`,   
  `${process.env.PUBLIC_URL}/assets/img/new_images/report-in-job-fair-cum-hr-summit/news07.jpg`,   
  `${process.env.PUBLIC_URL}/assets/img/new_images/report-in-job-fair-cum-hr-summit/news08.jpg`,   
  `${process.env.PUBLIC_URL}/assets/img/new_images/report-in-job-fair-cum-hr-summit/news09.jpg`,   
  
   
]


const report_in_job_fair_cum_hr_summit_text = "MVN University organized press conference to give information regarding three event that is to be held on 14, 15 & 16 March as International conference, HR Summit & Job Fair. Dr. Arun Garg Vice Chancellor of MVN University informed in the press conference that there are several new courses going to start in campus with university focus & emphasis also on placement.";



const report_on_press_confrence_for_beginning_of_new_courses =[
  `${process.env.PUBLIC_URL}/assets/img/new_images/report-on-press-confrence-for-beginning-of-new-courses/some10.jpg`,   
  `${process.env.PUBLIC_URL}/assets/img/new_images/report-on-press-confrence-for-beginning-of-new-courses/some11.jpg`,
 
   
]


const report_on_press_confrence_for_beginning_of_new_courses_text = "MVN University organized press conference to give information regarding three events that is to be held on 14-16 March, 2024 as International conference, HR Summit & Job Fair. Honorable Vice Chancellor of MVN University Dr. Arun Garg informed in the press conference that there are several new courses going to start in campus with university focus & emphasis also on placement.";



const diwali_milan_samaroh_2024_dated_25_october_2024 =[
  `${process.env.PUBLIC_URL}/assets/img/new_images/diwali-milan-samaroh-2024-dated-25-october-2024/newimg01.jpg`,  
  `${process.env.PUBLIC_URL}/assets/img/new_images/diwali-milan-samaroh-2024-dated-25-october-2024/newimg02.jpg`,
  `${process.env.PUBLIC_URL}/assets/img/new_images/diwali-milan-samaroh-2024-dated-25-october-2024/newimg03.jpg`, 
  
   
]





  

  if(screenname == '2nd-international-conference-photography-on-14-march-2024'){ 
    newarry = gallery_iamges;
  }else if(screenname == 'freshers-welcome-party'){ 
    newarry = freshers_welcome_party;
  }else if(screenname == '8th-convocation-ceremony-photo-on-16-march-2024'){ 
    newarry = convocation8th_ceremony;
  }else if(screenname == 'drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024'){ 
    newarry = drrajeev_april_2024;
  }else if(screenname == 'envisage-2k24-photo-2223-feb-2024'){ 
    newarry = envisage_2k24_photo;
  }else if(screenname == 'farewell-party-celebration-pharmacy-dept-on-31-may-2024'){ 
    newarry = farewell_party_celebration;
  }else if(screenname == 'international-yoga-day-celebration-photo-on-10-june-2024'){ 
    newarry = international_yoga_day;
  }else if(screenname == 'mega-job-fair-cum-hr-summit-on-15-march-2024'){ 
    newarry = mega_job_fair;
  }else if(screenname == 'news-reporters-visit-on-12-03-2024'){ 
    newarry = news_reporters_visit;
  }else if(screenname == 'sh-gopal-sharma-death-anniversary-photo-on-18-dec-2023'){ 
    newarry = sh_gopal_sharma_death_anniversary;
  }else if(screenname == 'tug-of-war-photo-12-feb-2024'){ 
    newarry = tug_of_war_photo;
  }else if(screenname == 'urja-2k23-freshers-party-celebration-photo-on-24-nov-2023'){ 
    newarry = urja_2k23_freshers_party;
  }else if(screenname == 'health-eye-checkup-camp-09-oct-2024'){ 
    newarry = health_eye_checkup_camp_09_oct_2024;
  }else if(screenname == 'report-on-celebration-of-yoga-diwas'){ 
    newarry = report_on_celebration_of_yoga_diwas;
    newarry_text = report_on_celebration_of_yoga_diwas_test;
  }else if(screenname == 'report-on-international-conference-of-pharmacay'){ 
    newarry = report_on_international_conference_of_pharmacay;
    newarry_text = report_on_international_conference_of_pharmacay_test;
  }else if(screenname == 'report-on-educationists-lecture-in-iternational-conference'){ 
    newarry = report_on_educationists_lecture_in_iternational_conference;
    newarry_text = report_on_educationists_lecture_in_iternational_conference_text;
  }else if(screenname == 'report-on-annual-sports-meet'){ 
    newarry = report_on_annual_sports_meet;
    newarry_text = report_on_annual_sports_meet_text;
  }else if(screenname == 'report-on-19th-aniversaryof-shri-gopal-sharma-ji-19-dec-2023'){ 
    newarry = report_on_19th_aniversaryof_shri_gopal_sharma_ji_19_dec_2023;
    newarry_text = report_on_19th_aniversaryof_shri_gopal_sharma_ji_19_dec_2023_text;
  }else if(screenname == 'report-in-job-fair-cum-hr-summit'){ 
    newarry = report_in_job_fair_cum_hr_summit;
    newarry_text = report_in_job_fair_cum_hr_summit_text;
  }else if(screenname == 'report-on-press-confrence-for-beginning-of-new-courses'){ 
    newarry = report_on_press_confrence_for_beginning_of_new_courses;
    newarry_text = report_on_press_confrence_for_beginning_of_new_courses_text;
  }else if(screenname == 'diwali-milan-samaroh-2024-dated-25-october-2024'){ 
    newarry = diwali_milan_samaroh_2024_dated_25_october_2024;
     
  }else{
    newarry = gallery_iamges;
  }
 



    const closeLightbox = () => {
        this.state.open = true;
      };

      
 

    

  return (


    <>
      <NewMain />
      <Bredcom title="Home" subtitle={screenname} />
      
      <section class="gallerysec">
          <div className='container'>
 
              <p>{newarry_text}</p>

                {newarry.map((item, index) => (
                <div className='one_gallery'>
                    <ModalImage   small={item} large={item}    />    
                </div>  
                ))}
            
              
          </div>
      </section>

     <div className='clear'></div>       


    </>
  )
}

export default GalleryDetail