import { Grid, Typography } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";

const MVNUniversityabout=()=>{
return (
<Grid container spacing={2}>
<Grid item xs={12}>
    <MainTitle title="About MVN University " />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >MVN University is a prestigious educational institution committed to providing high-quality education and fostering holistic development in its students. Located in Haryana, India, MVN University offers a diverse range of undergraduate, postgraduate, and doctoral programs across various disciplines.
Our Vision: At MVN University, our vision is to be a globally recognized institution that excels in teaching, research, and innovation. We aim to produce highly skilled professionals who can contribute effectively to society and meet the challenges of the ever-evolving world.
</Typography>
    </Grid>
    </Grid>

)
}
export default MVNUniversityabout;